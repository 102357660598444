import { valueType } from 'antd/lib/statistic/utils';
import { DataNode } from 'antd/lib/tree';

export type ValidationError = {
  field: string;
  message: string;
};

export type Admin = {
  id: number;
  username: string;
  email: string;
  status: number;
  role: AdminRoles;
  phone: string;
};

export enum AdminRoles {
  SUPER_ADMIN = 100,
  ADMIN = 10,
  MANAGER = 1,
}

export type Option = {
  id: number;
  title: string;
  status: number;
  values?: OptionValue[];
};

export type OptionValue = {
  id: number;
  option_id: number;
  title: string;
  sort: string;
  status: number;
};

export interface Category extends DataNode {
  id: number;
  title: string;
  children: Category[];
  parent_id: string | null;
  status: number;
  productsCount: number;
  values?: OptionValue[];
  header_image: string | null;
  promotion_image: string | null;
  promotion: boolean;
  color: string;
}

export type Brand = {
  id: number;
  title: string;
  status: number;
  promotion: boolean;
  logo: string | null;
};
export type ValueType = {
  option_id: number;
    price_types:number

  value_id: number;
};
export type Product = {
  id: number;
  title: string;
  slug: string;
  status: number;
  sku?: string;
  released: number;
variant : valueType[]
  description: string;
  price_types: ValueType[];
  images: string[];
  videos: string[];
  categories: string[];
  options: [
    {
      option_id: number;
      values_id: number[];
    },
  ];
  table_title: string;
  variants?: {
    values: { option: number; value: number }[];
    sku?: string;
    price: number;
    sale_price?: number;
    stock: number;
    sale_from?: number | null;
  }[];
  variants_10?: {
    values: { option: number; value: number }[];
    sku?: string;
    price: number;
    sale_price?: number;
    stock: number;
    sale_from?: number | null;
  }[];
  variants_100?: {
    values: { option: number; value: number }[];
    sku?: string;
    price: number;
    sale_price?: number;
    stock: number;
    sale_from?: number | null;
  }[];

  brand: Brand | null;
  brand_id: string | null;

  top: boolean;
  sale: boolean;

  with_categories: Category[];
};

export type Bundle = {
  id: number;
  title: string;
  description: string;
  images: string[];
  position: number;
  status: number;
  products: { product: Product; quantity: number }[];
  products_price: number;
  price: number;
};

export type TermType = {
  id: string;
  title: string;
  content: string;
  date: number;
  type: number;
  status: number;
};

export enum TermsType {
  PRIVACY = 10,
  TERMS = 100,
}

export type ShipmentType = {
  id: number;
  label: string;
  price: number;
  free_from: number;
  desc?: string;
};

export enum UserType {
  CLINIC = 1,
  DEALER = 10,
  DISTRIBUTOR = 100,
}

export type User = {
  avatar: string | null;
  created_at: number;
  email: string;
  fname: string;
  lname: string;
  status: number;
  id: number;
  facebook_id: string;
  google_id: string;
  apple_id: string;
  type: UserType;
  credits?: number;
};

export type Order = {
  id: number;
  user: User;
  status: number;
  products: OrderProduct[];
  bundles: OrderBundle[];
  address_id: number;
  shipment_type: number;
  shipment_price: number;
  created_at: number;
  address: Address;
  total: number;

  catalog_products?: Product[];
  history: OrderHistory[];

  credits_payed: number;

  payment_info?: {
    TranzilaTK: string;
    cardtype: number;
  };

  platform_type?: OrderPlatformType;
};

// export type Order = {
//   id: number;
//   number: string;
//   status: number;
//   user_id: number | null;
//   account: User | null;
//   address: {
//     flat: string;
//     building: string;
//     city: string;
//     country: string;
//     street: string;
//     zip: string;
//   };
//   products: OrderProduct[];
//   total: number;
//   currency: string;
//   user: {
//     email: string;
//     first_name: string;
//     last_name: string;
//     phone: string;
//   };
//   created_at: number;
//   // payment: PaymentInfo;

//   shipment_id?: string;
//   // shipment: Shipment | null;
//   shipment_price?: number;

//   history: OrderHistory[];
// };

export type   OrderProduct = {
  id: number;
  product_id: number;
  variant: {
    option_id: number;
    value_id: number;
  }[];
  quantity: number;
  price: number;
  product?: Product;
};

export type OrderBundle = {
  bundle_id: number;
  title: string;
  products: {
    product_id: number;
    variant: {
      option_id: number;
      value_id: number;
    }[];
    quantity: number;
  }[];

  quantity: number;
  price: number;
  //  products_price: number;
};

// export type OrderProduct = {
//   discount: number;
//   price: number;
//   product_id: string;
//   quantity: number;
//   sale_price: number;
//   variant?: any;
//   product: OrderProductProduct;
// };

export type OrderProductProduct = {
  id: number;
  title: string;
  sku: string | null;
  slug: string;
  status: number;
  price: number;
  sale_price: number | null;
  sale_from: number | null;
  price_min: number;
  price_max: number;

  short: string;
  description: string;

  images: string[];
  categories: string[];

  options: ProductOption[];

  // properties: {
  //   property: Property;
  //   value: PropertyValue;
  // }[];
  // variants: Variant[];
  // variants_images: {
  //   value: string;
  //   images: TypeFile[];
  // }[];

  // stock: number;

  // real_price: number;
};

export type ProductOption = {
  id: number;
  title: string;
  values?: OptionValue[];
};

export type OrderHistory = {
  created_at: number;
  notify: boolean;
  status: number;
  text: string;
};

export type Address = {
  id: number;
  title: string;
  fname: string;
  lname: string;
  city: string;
  street: string;
  house: string;
  appartment: string;
  state: string;
  country: string;
  zip: string;
  phone: string;
};

export type FAQ = {
  id: number;
  question: string;
  answer: string;
  created_at: number;
  updated_at: number;
  status: number;
  position: number;
};

export type Banner = {
  id: number;
  created_at: number;
  updated_at: number;
  image: string | null;
  image_web: string | null;
  position: number;
  status: BannerStatus;
  product_id: number | null;
  bundle_id: number | null;
  external_url: string | null;
};

export enum BannerStatus {
  NOT_ACTIVE = 9,
  ACTIVE = 10,
}

export type ForumPost = {
  id: number;
  created_at: number;
  title: string;
  description: string;
  topic: string;
  photo: string | null;
  status: ForumStatus;
  myLike_id: number | null;
  likes: number;

  user_id: number;
  comments_count: number;

  user: User;
};

export enum ForumStatus {
  NOT_ACTIVE = 0,
  ACTIVE = 10,
}

export type Comment = {
  id: number;
  post_id: string;
  created_at: number;
  text: string;

  user_id?: string;
  user: User;
};

export type Message = {
  to_user: string;
  id: number;
  title: string;
  text: string;
  created_at: number;
  product_id: number | null;
  bundle_id: number | null;
  message_text: string | null;
  status: MessageStatus;
};

export enum MessageStatus {
  NOT_ACTIVE = 0,
  ACTIVE = 10,
}

export type Credit = {
  id: number;
  created_at: number;
  user_id: number;
  amount: number;
  comment: string;
  type: CreditType;
  user?: User;
};

export enum CreditType {
  TYPE_IN = 1,
  TYPE_OUT = -1,
}

export enum OrderPlatformType {
  PLATFORM_TYPE_APP = 10,
  PLATFORM_TYPE_WEB = 100,
}
